import * as React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import Layout from "../components/Layout";
import Banner from "../components/Banner";

const Container = styled.div`
  max-width:1000px;
  margin:0 auto;
  padding:50px 15px;
  
  h1{
    
  }
  
  h2{
   font-size: 28px; 
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  h3{
    font-size: 16px;
    display: inline;
    font-weight: 500;
  }
  
  p{
    margin-bottom: 12px;
  }
  
  ol{
    margin-top: 20px;
    margin-bottom: 20px;
    
    li{
        margin-bottom: 0px;
      
    }
  }
`;


const Contactpage = () => {
  return (
    <>
      <Helmet htmlAttributes={{
        lang: "pl"
      }}>
        <title>Mediacje - Kancelaria Notarialna M.Dąbrowska & J.Szeszkowska</title>
        <meta name="description"
              content="Kancelaria Notarialna Warszawa Centrum. Mediacje, akty notarialne, wymagane dokumenty i opłaty. Informacje o czynnościach notarialnych. Notariusz śródmieście." />
      </Helmet>
      <Layout>
        <Banner />

        <Container>
          <h1>Mediacje</h1>

          <p>
            Mediacja to metoda ugodowego, poufnego, dobrowolnego rozwiązywania konfliktu przez same strony, z udziałem
            bezstronnego mediatora, który pomaga stronom w osiągnięciu porozumienia.
          </p>

          <p>
            Mediacja może być prowadzona na mocy skierowania stron postępowania sądowego do mediacji przez sąd, jak
            również na podstawie umowy o mediację.
          </p>

          <p>
            Prowadzimy mediacje we wszelkich sprawach cywilnych, rodzinnych i gospodarczych, w tym w szczególności
            takich jak:
          </p>

          <ol>
            <li>
              <h3>mediacje w sporach związanych ze współwłasnością nieruchomości</h3> -
              mające na celu określenie podziału nieruchomości do korzystania; rozliczenia pożytków i nakładów, sposobu
              zniesienia współwłasności nieruchomości;
            </li>
            <li>
              <h3>mediacje w sprawach o przeniesienie własności nieruchomości </h3> -
              w sprawach o wykonanie umów przedwstępnych sprzedaży nieruchomości, umów deweloperskich oraz innych umów
              zobowiązujących;
            </li>
            <li>
              <h3>mediacje w sprawach o podział majątku wspólnego </h3> -
              podział majątku po rozwodzie lub ustanowieniu rozdzielności majątkowej, rozliczanie nakładów z majątku
              osobistego na majątek wspólny i odwrotnie;
            </li>
            <li>
              <h3>mediacje w sprawach o związanych z dziedziczeniem spadków</h3> -
              w sprawach o dział spadku, zachowek, o wykonanie zapisów, spłatę długów spadkowych, egzekucję należności
              wchodzących w skład spadku;
            </li>
            <li>
              <h3>mediacje w sprawach korzystania z cudzej własności</h3> -
              w zakresie ustanowienia służebności czy wynagrodzenie za bezumowne korzystanie;
            </li>
            <li>
              <h3>mediacje w sprawach o rozwód </h3> -
              mające na celu szybkie przeprowadzenie sprawy w sądzie oraz określenie wzajemnych relacji po ustaniu
              małżeństwa (alimenty, podział majątku, opieka nad dziećmi itp.);
            </li>
            <li>
              <h3>mediacje w sprawach o alimenty </h3> -
              mające na celu określenia sposobu wykonywania obowiązku alimentacyjnego w rodzinie a także pomiędzy byłymi
              małżonkami;
            </li>
            <li>
              <h3>mediacje w sprawach gospodarczych </h3> -
              wszelkie spory wynikające z umów i innych zdarzeń prawnych pomiędzy przedsiębiorcami, także w zakresie
              sporów pomiędzy wspólnikami i akcjonariuszami.
            </li>
          </ol>

          <p><b>
            Naszą działalność w zakresie mediacji prowadzimy w ramach Ośrodka Mediacyjnego Stowarzyszenia Notariuszy
            Rzeczypospolitej Polskiej.
          </b></p>

          <h2>Zalety mediacji</h2>


          <ol>
            <li>
              mediacja jest zdecydowanie mniej kosztowna niż proces sądowy, nie generuje kosztów opinii biegłych, w
              wypadku zakończenia mediacji ugodą następuje zwrot przez sąd 75% wysokości kosztów opłaty sądowej,
            </li>
            <li>
              mediacja jest szybsza niż proces sądowy, to od stron zależy jak szybko i jak często chcą się spotykać i
              uczestniczyć w mediacji, to strony decydują o czasie, miejscu i sposobie prowadzenia rozmów,
            </li>
            <li>
              strony same decydują o sposobie rozwiązania sporu, każde ze stron może zrezygnować z części żądań, zaś
              orzeczenie sądu może nie satysfakcjonować żadnej ze stron,
            </li>
            <li>
              mediacja daje możliwość kompleksowego załatwienia różnych spraw (sąd związany jest granicami pozwu),
            </li>
            <li>
              zasadą mediacji jest poufność,
            </li>
            <li>
              mediacja może być szansą na pojednanie i naprawienie wzajemnych relacji,
            </li>
            <li>
              ugoda zawarta przed mediatorem zatwierdzona przez sąd, po nadaniu jej klauzuli wykonalności jest tytułem
              egzekucyjnym.
            </li>
          </ol>

          <h2>Koszty mediacji</h2>

          <p>
            Koszty mediacji ze skierowania sądu określone są rozporządzeniem Ministra Sprawiedliwości z dnia 20 czerwca 2016 roku w sprawie wysokości wynagrodzenia i podlegających zwrotowi wydatków mediatora w postępowaniu cywilnym.
          </p>

          <p>
            W sprawach o prawa majątkowe wynagrodzenie mediatora wynosi 1% wartości przedmiotu sporu, jednak nie mniej niż 150,00 złotych i nie więcej niż 2.000,00 złotych za całość postępowania mediacyjnego. Ponadto zwrotowi podlegają także udokumentowane i niezbędne wydatki mediatora poniesione w związku z przeprowadzeniem mediacji wskazane w § 3 powołanego rozporządzenia.
          </p>

          <p>
            W sprawach o prawa majątkowe, w których wartości przedmiotu sporu nie da się ustalić, oraz w sprawach o prawa niemajątkowe wynagrodzenie mediatora za prowadzenie postępowania mediacyjnego wynosi za pierwsze posiedzenie 150,00 złotych, a za każde kolejne – 100,00 złotych, łącznie nie więcej niż 450,00 złotych.
          </p>

          <p>
            Koszty mediacji umownej ustalane są indywidualnie w umowie o mediację.
          </p>

        </Container>
      </Layout>
    </>

  );
};
export default Contactpage;